import React from "react";

const Banner = () => {
  return (
    <div
      className="py-2 px-4 text-center mt-40 lg:mt-20 mb-6 height-[24px]"
      data-aos="fade-down"
      data-aos-delay="400"
    >
      
    </div>
  );
};

export default Banner;
